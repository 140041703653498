import type { ActionFunctionArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import { Form, useActionData } from "@remix-run/react";
import { AuthorizationError } from "remix-auth";
import { useRemixForm } from "remix-hook-form";
import Button from "~/components/Button";
import Input from "~/components/Input";
import { authenticator } from "~/services/auth/authenticator.server";
import { LoginActionResolver } from "~/services/auth/validator";

export const action = async ({ request }: ActionFunctionArgs) => {
  try {
    await authenticator.authenticate("form", request, {
      throwOnError: true,
      successRedirect: "/",
    });
  } catch (error) {
    if (error instanceof AuthorizationError) {
      return json({ message: error.message }, 401);
    }

    throw error;
  }
};

export default function FormRoute() {
  const actionData = useActionData<typeof action>();

  const { handleSubmit, formState, register } = useRemixForm({
    mode: "onSubmit",
    defaultValues: {
      password: "",
      username: "",
    },
    resolver: LoginActionResolver,
  });

  return (
    <div className="flex min-h-screen flex-col justify-center">
      <div className="mx-auto flex w-1/3 min-w-96 items-center gap-24">
        <div className="w-40">
          <img src="/images/logo.svg" alt="TSI-SA" />
        </div>
        <Form
          className="flex grow flex-col gap-4"
          method="post"
          onSubmit={handleSubmit}
        >
          <Input
            label="Identifiant"
            type="text"
            {...register("username")}
            error={formState.errors.username?.message}
          />

          <Input
            label="Mot de passe"
            type="password"
            {...register("password")}
            error={formState.errors.password?.message}
          />

          {actionData ? (
            <div className="text-red-error">{actionData.message}</div>
          ) : null}

          <Button
            type="submit"
            label="Se connecter"
            isLoading={formState.isLoading || formState.isSubmitting}
          />
        </Form>
      </div>
    </div>
  );
}
